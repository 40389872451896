div.tabela-unidades {
  max-height: 500px;
  overflow-y: auto;
  padding: 15px;

  table {
    .col-check {
      flex: 0 0 6.66667%;
      max-width: 6.66667%;
    }

    .col-dre {
      flex: 0 0 10%;
      max-width: 10%;
    }

    thead {
      th {
        border: 1px solid #dadada;
      }
    }
    tbody {
      tr {
        td {
          border: 1px solid #dadada;
        }
      }
    }
    th,
    td {
      padding: 6px 12px;
      background: #fcfcfc;
    }
    th {
      background: #eee;
    }
  }
}

div.tabela-unidades-selecionadas {
  table {
    width: 100%;
    border: 1px solid gray;
    thead {
      th {
        border: 1px solid #dadada;
      }
    }
    tbody {
      tr {
        td {
          border: 1px solid #dadada;
        }
      }
    }
    th,
    td {
      padding: 4px 8px;
      background: #fcfcfc;
    }
    th {
      background: #eee;
    }
  }
  span.delete {
    margin-left: 2em;
    font-weight: bold;
    background-color: #602a2a;
    padding: 5px 10px;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    &:hover {
      background-color: #602a2a;
    }
  }

  .tabela-lotes {
    border: 1px solid #e9eaea;

    .titulo-lote {
      font-size: 18px;
      padding: 12px;
    }

    .btn-unidades {
      width: 20%;
      border-left: 1px solid #e9eaea;
      border-radius: 0px;
    }
  }

  .card {
    background: #f5f6f8;
    border: 1px solid #dadada;
  }

  .button-red-outline {
    border-color: #b40c02;
    color: #b40c02;
    background-color: #ffffff;
    font-weight: bold;
  }
}

.checkbox-label {
  color: gray;
  font-size: 14px;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    &:checked {
      ~ .checkbox-custom {
        background-color: #602a2a;
        border-radius: 5px;
        transform: rotate(0deg) scale(1);
        opacity: 1;
        border: 1px solid #602a2a;
        &:after {
          transform: rotate(45deg) scale(1);
          opacity: 1;
          left: 7px;
          top: 2px;
          width: 6px;
          height: 12px;
          border: solid white;
          border-width: 0 2px 2px 0;
          background-color: transparent;
          border-radius: 0;
        }
      }
    }
  }
  .checkbox-custom {
    position: flex;
    top: 0px;
    left: 0px;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border-radius: 3px;
    border: 1px solid gray;
    cursor: pointer;
    &:after {
      position: absolute;
      content: "";
      left: 12px;
      top: 12px;
      height: 0px;
      width: 0px;
      border-radius: 5px;
      border: solid #602a2a;
      border-width: 0 3px 3px 0;
      transform: rotate(0deg) scale(0);
      opacity: 1;
    }
    &.radio {
      border-radius: 15px;
    }
  }
  span.text {
    padding-left: 2em;
  }
}

.checkbox-label,
.checkbox-custom {
  top: unset;
  position: relative;
  padding-right: 20px;
  &.small {
    padding-right: 0px;
  }
}

div.input-group.vigencia-contrato {
  .col-6 {
    padding-right: 0;
    margin-right: -15px;
  }
  select {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  input {
    height: 38px !important;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .input-group-append {
    padding-top: 8px;
    .input-group-text {
      height: 38px;
      font-size: 14px;
    }
  }
}
.tabela-resultado-buscas-unidades {
  width: 100%;
}
