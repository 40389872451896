.tabela-atas {
  .table td {
    border-top: 0px !important;
    padding: 2px !important;
    padding-top: 15px !important;
    font-weight: bold;
  }

  .expand-ata {
    margin-top: 2px;
    margin-left: 2px;
  }

  .texto-vermelho {
    color: red;
  }
}
