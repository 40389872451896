.filtro {
  .card.card-w-title-ata {
    padding: 0px;
  }
}
.ata-grid {
  margin-left: 0;
}
.filtro-head-ata {
  margin-right: -0.5em !important;
}
