.sub-titulo {
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #602a2a;
}

.ponto {
  display: flex;
  align-self: end;
  font-size: 22px;
}
