.filtro {
  .card.card-w-title-contrato {
    padding: 0px;
  }
}
.contrato-grid {
  margin-left: -0.25em;
}
.filtro-head-contrato {
  margin-right: -0.5em !important;
}
.no-padding-right {
  padding: 0.5em 0 0.5em 0.5em;
}
.no-padding-left {
  padding: 0.5em 0.5em 0.5em 0;
}
.p-card .p-card-body {
  padding: 0;
}
.p-dropdown-items {
  width: 500px;
}
