$mainColor: #602a2a;
.coad-anexos {
  .dzu-dropzone {
    padding: 9%;
    background: #fafafa;
    border-style: dotted;
    overflow: hidden;
    .dzu-inputLabel {
      color: $mainColor;
    }
    .dzu-inputLabelWithFiles {
      background-color: $mainColor;
      color: #fff;
    }
  }
  .row-anexo {
    justify-content: space-between;
    align-items: end;
  }
  .title-anexo {
    color: #602a2a;
    font-size: 20px;
    font-weight: bold;
    padding-left: 1rem;
  }
  .aviso-anexo {
    border: 1px;
    border: #602a2a;
    text-align: right;
    padding-right: 1rem;
  }
}

.btn-empenho {
  margin-top: 28px;
}

.p-grid {
  width: 100%;
}
.p-tabview .p-tabview-nav li {
  margin-bottom: -0.25rem;
  border-radius: 0.5rem;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  border-radius: 0.5rem;
  background-color: #f5f5f5;
}
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background-color: #602a2a;
  border-color: #602a2a;
  border-radius: 0.5rem;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  border-radius: 0.5rem;
  box-shadow: inset 0 0 0 0.2rem #602a2a;
}
.comeback {
  padding-right: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: row-reverse;
}
.spacing2 {
  padding-right: 2rem;
}
.cards-contratos {
  margin-left: 0;
  .card-servico {
    padding-left: 0;
  }
  .card-contagem {
    padding-right: 0;
  }
}
.desativar {
  pointer-events: none;
}

.form-aditamentos {
  padding: 16px;

  .check-objeto {
    display: flex;
    align-items: center;
    padding: 8px;
    margin-bottom: 8px;
    border: 1px solid #602a2a;
    border-radius: 3px;

    label {
      margin-bottom: 0;
      margin-left: 12px;
    }
  }

  .checked {
    background: rgba(67, 30, 30, 0.1);
  }

  h5 {
    width: 100%;
    border-bottom: 0.5px solid #ccc;
    line-height: 0.2em;
    margin: 16px 0;

    span {
      background: #fff;
      padding-right: 6px;
    }
  }

  .tabela-aditamentos {
    width: 100%;

    .grid-row {
      display: flex;
    }

    .grid-item {
      width: 100%;
      border: 1px solid #c5c5c5;
      padding: 16px;
    }

    .titulo-item {
      font-weight: bold;
      margin-bottom: 0.2em;
    }
  }
  .icones-acoes {
    .fas {
      color: #b40c02;
      cursor: pointer;
    }
  }
  .link-anexo {
    font-weight: 200;
  }
  .link-deletar {
    font-weight: bold;
    cursor: pointer;
    margin-top: -2rem;
  }
  .titulo-termo {
    color: #602a2a;
    font-size: 18px;
    font-weight: 600;
    padding-top: 10px;
  }

  .texto-suspensao {
    color: #b40c02;
    text-align: right;
  }

  .dias-suspensao {
    justify-content: space-between;
    padding: 0 15px;
  }

  .red {
    color: red;
  }
}
