@media (min-width: 1025px) {
  .layout-wrapper {
    &.layout-overlay {
      .layout-sidebar {
        left: -250px;
      }

      .layout-topbar {
        left: 0;
      }

      .layout-main,
      .layout-footer {
        margin-left: 0;
      }

      &.layout-overlay-sidebar-active {
        .layout-sidebar {
          left: 0;
        }

        .layout-topbar {
          left: 250px;
        }
      }
    }

    &.layout-static {
      .layout-sidebar {
        left: 0;
      }

      .layout-topbar {
        left: 250px;
      }

      .layout-main,
      .layout-footer {
        margin-left: 250px;
      }

      &.layout-static-sidebar-inactive {
        .layout-sidebar {
          left: -250px;
        }

        .layout-topbar {
          left: 0;
        }

        .layout-main,
        .layout-footer {
          margin-left: 0;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .layout-wrapper {
    .layout-topbar {
      left: 0;
    }

    .layout-main,
    .layout-footer {
      margin-left: 0;
    }

    .layout-sidebar {
      left: -250px;
      margin-top: 50px;
    }

    .layout-mask {
      display: none;
      position: fixed;
      width: 100%;
      height: 100%;
      top: 50px;
      left: 0;
      z-index: 998;
      background-color: $maskBgColor;
      @include opacity(0.7);
    }

    &.layout-mobile-sidebar-active {
      .layout-sidebar {
        left: -0;
      }

      .layout-mask {
        display: block;
      }
    }
  }

  .body-overflow-hidden {
    overflow: hidden;
  }
}
