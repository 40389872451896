.filtro {
  .p-card-footer {
    padding-top: 0px;
  }
  .card.card-w-title {
    padding-bottom: 0px;
    width: 100%;
  }

  .card {
    border: none;
  }
  padding-left: 0.25rem;
  padding-right: 0;
  margin-right: 0;
}

.p-card {
  box-shadow: none;
}
